import React, { Component } from 'react';
import axios from 'axios';
import { Container, Tabs, Tab, Col, Form, Button, Alert, InputGroup } from 'react-bootstrap';

import RefreshIcon from '@material-ui/icons/Refresh';
import PageviewIcon from '@material-ui/icons/Pageview';
import PanToolOutlinedIcon from '@material-ui/icons/PanToolOutlined';
import BackspaceIcon from '@material-ui/icons/Backspace';
import YoutubeSearchedForIcon from '@material-ui/icons/YoutubeSearchedFor';

class App extends Component
{
  state = {  
    apiUrl:'https://sorgu.mo.org.tr/api',
    get ukodUrl() { return this.apiUrl + '/ukod_uret'; },
    checkForm:this.props.prm.checkForm,
    activeKey: 'sorgu',
    giden:{ sicilno:this.props.prm.ctrl.sicilno.val, belgeno:this.props.prm.ctrl.belgeno.val, ukod:'' },
    gelen:{ DURUM:'ILK', BILGI:{} }
  }

  // değişen form nesnelerini state'e alıyor
  defSet = e => this.setState(s => ({giden:{...s.giden,[e.target.name]:e.target.value}}));

  // işlem kodunu yeniliyor
  ukodUret = () => this.setState(s => ({ ukodUrl:s.apiUrl+'/ukod_uret/'+Date.now(), giden:{...s.giden,ukod:''} }));

  // tablar arası geçiş için
  selectTab = activeKey => this.setState({activeKey});

  // sonuç tabından dönmek için
  backBt = newq => {

    // yeni sorgu form silinecek
    if(newq !== '')
    {
      this.setState(s => ({giden:{...s.giden, sicilno:'', belgeno:''}}));
      window.history.pushState('', document.title, '/');
    }

    this.ukodUret();
    this.selectTab('sorgu');
  }

  // form gönderimi
  kontrolEt = async (event) => {

    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;

    if (form.checkValidity() === true)
    {
      this.setState({checkForm:false});

      var form_data = new FormData();

      for ( var key in this.state.giden )
        { form_data.append(key, this.state.giden[key]); }

      // console.log(this.state.giden);

      await axios.post(this.state.apiUrl+'/kontrol_et', form_data, {withCredentials: true})
      .then((response) => {
        // console.log(response.data);
        this.setState({gelen:response.data});
        this.selectTab("gelen");
      })
      .catch((error) => {
        console.log(error);
      });
    }
    
    this.setState({checkForm:true});

  }
 
  render()
  {
    const prm = this.props.prm;
    
    return (
      <React.Fragment>
        <header>
          <div id="logo" />
          <div className="mainTitle">Belge Sorgulama</div>
        </header>
        <Container>
          <Tabs transition={false} defaultActiveKey="sorgu" activeKey={this.state.activeKey} onSelect={this.selectTab}>
            <Tab eventKey="sorgu" title="Sorgu">
              <br />
              <Form noValidate validated={this.state.checkForm} onSubmit={this.kontrolEt}>
                <Alert variant="warning">
                  Bu form kullanılarak Mimarlar Odası üyeleri için üretilen "Üye Tanıtma Belgelerinin" doğruluğu kontrol edilebilir.
                </Alert>
                <Form.Row>
                  <Form.Group as={Col} sm={6}>
                    <Form.Label htmlFor="sicilno">Üye Sicil Numarası</Form.Label>
                    <Form.Control onChange={e => this.defSet(e)} value={this.state.giden.sicilno}  id="sicilno" name="sicilno" required pattern={prm.ctrl.sicilno.pat} type="text" autoComplete="off" />
                    <Form.Control.Feedback type="invalid">Üye Sicil Numarası Gerekli</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} sm={6}>
                    <Form.Label htmlFor="">Tescil / Belge Numarası</Form.Label>
                    <Form.Control onChange={e => this.defSet(e)} value={this.state.giden.belgeno} id="belgeno" name="belgeno" required pattern={prm.ctrl.belgeno.pat} type="text" autoComplete="off" />
                    <Form.Control.Feedback type="invalid">Belge Numarası Gerekli</Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} sm={6}>
                    <Form.Label htmlFor="ukod">Güvenlik Kodu</Form.Label>
                    <InputGroup>
                      <InputGroup.Prepend><InputGroup.Text className="ukodWrapper"><img src={this.state.ukodUrl} alt="" /></InputGroup.Text></InputGroup.Prepend>             
                      <Form.Control style={{textTransform:'uppercase'}} onChange={e => this.defSet(e)} value={this.state.giden.ukod} id="ukod" name="ukod" required pattern={prm.ctrl.ukod.pat} type="text" autoComplete="off" />
                      <InputGroup.Append>
                        <Button id="ukodRefresh" onClick={this.ukodUret} title="Kodu Yenile" type="button" variant="warning"><RefreshIcon /></Button>
                      </InputGroup.Append>
                      <Form.Control.Feedback type="invalid">Güvenlik Kodu Gerekli</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group as={Col} sm={6}>
                    <Form.Label>&nbsp;</Form.Label>
                    <Button block={true} disabled={!this.state.checkForm} type="submit" variant="outline-primary">
                      {this.state.checkForm?(<div><PageviewIcon /> Kontrol Et</div>):(<div><PanToolOutlinedIcon /> Lütfen Bekleyiniz</div>)}
                    </Button>
                  </Form.Group>
                </Form.Row>
                
              </Form>
            </Tab>
            <Tab eventKey="gelen" title="Sonuç" className="p-2" disabled={this.state.gelen.DURUM==='ILK'}>
            {this.state.gelen.DURUM===true?(
              <div>
              {this.state.gelen.BILGI.TESCILNO?(<div>
                <Alert variant="success">
                  <h4 className="alert-heading">{this.state.gelen.BILGI.TESCILNO}</h4>
                  <b>{this.state.gelen.BILGI.TARIH}</b> tarihi itibariyle <b>{this.state.gelen.BILGI.SICILNO}</b> sicil nolu üyenin 6235 sayılı Türk Mühendis ve Mimar Odaları Birliği kanunu ve 
                  Mimarlar Odası serbest mimarlık hizmetlerini uygulama, tescil ve mesleki denetim yönetmeliği kapsamında <b>{this.state.gelen.BILGI.YIL}</b> yılı 
                  serbest mimarlık hizmetleri büro tescil belgesi aktiftir.
                  <br/>
                  Serbest mimarlık hizmetleri büro tescil belgesinin geçerlilik süresi için belgenin aslının incelenmesi gerekmektedir.

                </Alert>

              </div>):(<div>

                {this.state.gelen.BILGI.ISOLD?(<Alert variant="danger"><b>Sorgulama yaptığınız belgenin veriliş tarihi bir yıldan daha eskidir.</b></Alert>):''}

                <Alert variant="success">
                <h4 className="alert-heading">{this.state.gelen.BILGI.SAYI}</h4>
                Sorguladığınız belge, <b>{this.state.gelen.BILGI.MAKAM}</b> verilmek üzere <b>{this.state.gelen.BILGI.TARIH}</b> tarihinde <b>{this.state.gelen.BILGI.SICILNO}</b> sicil numaralı üyemiz için üretilmiştir.
                <hr />
                <b>Açıklama : </b>{this.state.gelen.BILGI.ACIKLAMA}
                </Alert>
              </div>)}

              <Button block={true} onClick={e => this.backBt('nq')} title="Yeni Sorgu" type="button" variant="outline-success">Yeni Sorgu <YoutubeSearchedForIcon /></Button>
              </div>
            ):(
              <div>
              <Alert variant="danger">
              <h4 className="alert-heading">Belge Bulunamadı!</h4>Lütfen bilgileri kontrol edip yeniden deneyiniz. <br/>
                Bilgilerin doğru olduğundan eminseniz, belgenin verildiği birim ile iletişime geçiniz.
                <hr />
                Not: Güvenlik Kodunu okuyamıyorsanız, yanındaki yenile butonuna basınız.
              </Alert>
              <Button block={true} onClick={e => this.backBt('')} title="Geri" type="button" variant="outline-danger">Geri <BackspaceIcon /></Button>
              </div>
            )}
            </Tab>
          </Tabs>
        </Container>
        <footer>
          <p></p>
        </footer>
      </React.Fragment>
    )
  }
}

export default App;
