import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import App from './App';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

/* ana hatlar */
class Child extends Component
{
  // constructor(props) { super(props); console.log(props); console.log(this.state); }
  // componentDidMount() { }

  render()
  {
    let { sicilno, belgeno } = this.props.match.params;

    const prm = {
      ctrl:{
        sicilno:{ pat:'[0-9]{1,7}', val:sicilno },
        belgeno:{ pat:'^[0-9]{1,3}-[0-9]{2}-[0-9]{4}.[0-9]{2}.[0-9]{2}-[0-9]{6}|[0-9]{1,2}-[0-9]{1,5}$', val:belgeno },
        ukod:{ pat:'^[0-9a-zA-Z]{3}$', val:'' }
      }
    };

    // değerler burada patterne göre kontrol ediliyor.
    // eslint-disable-next-line
    Object.keys(prm.ctrl).map(key => {
      const item = prm.ctrl[key];
      item.val = RegExp(item.pat).test(item.val)?item.val:'';
    });

    prm.checkForm = true; //prm.ctrl.sicilno.val !== '';
    return (<App prm={prm} />);

  }
}

/* Router durumları */
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Switch>
        <Route path="/blg/:sicilno/:belgeno" render={(props) => <Child {...props} /> } />
        <Route path="/btb/:sicilno/:belgeno" render={(props) => <Child {...props} /> } />
        <Route path="/" render={(props) => <Child {...props} /> } />
      </Switch>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);